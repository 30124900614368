@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.break-keep {
  word-break: keep-all;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");

@layer base {
  select {
    @apply focus:outline-none;
  }
}

@layer components {
  .textfield {
    @apply px-3 h-8 border border-gray-200 rounded placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0 text-sm;
  }
  .select {
    @apply px-1 h-8 border border-gray-200 rounded placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }

  .switch {
    @apply flex justify-around py-0.5 bg-brand-2 rounded h-6 relative;
  }
  .switch-left {
    @apply transition-all transform duration-200 z-20;
  }
  .switch-right {
    @apply transition-all transform duration-200 z-20;
  }
  input:checked + .switch-left,
  input:not(:checked) + .switch-left + .switch-right {
    @apply text-white;
  }
  input:not(:checked) + .switch-left,
  input:checked + .switch-left + .switch-right {
    @apply text-gray-600;
  }
  .switch-slider {
    @apply absolute z-10 bg-brand-1 inset-y-0 rounded transform transition-all duration-200 ease-out;
  }
  input:checked + .switch-left + .switch-right + .switch-slider {
    @apply left-0 right-2/4;
  }
  input:not(:checked) + .switch-left + .switch-right + .switch-slider {
    @apply left-2/4 right-0;
  }
}
